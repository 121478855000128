import { useGetPreviewPanelsContext } from '@atlassian/preview-panels-api';

export const usePreviewPanelWidth = () => {
	const context = useGetPreviewPanelsContext();
	const emptyWidth = { width: undefined };

	if (!context) {
		return emptyWidth;
	}

	const {
		state: {
			width,
			panel: { content },
		},
	} = context;

	if (!content) {
		return emptyWidth;
	}

	return { width };
};
