import type { Action } from 'react-sweet-state';
import { createStore, createHook } from 'react-sweet-state';

type State = {
	isSpaceGettingStartedTourInProgress: boolean;
};

const initialState: State = {
	isSpaceGettingStartedTourInProgress: false,
};

const actions = {
	setIsSpaceGettingStartedTourInProgress:
		(value: boolean): Action<State> =>
		({ setState }) => {
			setState({
				isSpaceGettingStartedTourInProgress: value,
			});
		},
};

const Store = createStore({
	initialState,
	actions,
});

export const useSpaceGettingStartedTour = createHook(Store);
