export const PERFORMANCE_SUBJECT_comments = 'comments';
export const PERFORMANCE_SUBJECT_linkToPage = 'link-to-page';
export const PERFORMANCE_SUBJECT_mainLayout = 'main-layout';
export const PERFORMANCE_SUBJECT_manageWatcherDialog = 'manage-watcher-dialog';
export const PERFORMANCE_SUBJECT_navigation = 'Navigation';
export const PERFORMANCE_SUBJECT_navigationFMP = 'navigation-fmp';
export const PERFORMANCE_SUBJECT_sideNavigationFMP = 'side-navigation-fmp';
export const PERFORMANCE_SUBJECT_navigationView = 'NavigationView';
export const PERFORMANCE_SUBJECT_recentDropdown = 'recent-dropdown';
export const PERFORMANCE_SUBJECT_resolvedInlineCommentsDialog = 'resolved-inline-comments-dialog';
export const PERFORMANCE_SUBJECT_copyPageTree = 'copy-page-tree';
export const PERFORMANCE_SUBJECT_spaceDirectory = 'space-directory';
