// We have deprecated emotion. Please use compiled instead
// eslint-disable-next-line no-restricted-imports, @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css } from '@emotion/core';
import { styled } from '@compiled/react';

import { token } from '@atlaskit/tokens';
import {
	LEFT_PANEL_WIDTH,
	RIGHT_PANEL_WIDTH,
	LEFT_SIDEBAR_WIDTH,
	RIGHT_SIDEBAR_WIDTH,
	TOP_NAVIGATION_HEIGHT,
} from '@atlaskit/page-layout';

import { UNSAFE_MAIN_BLOCK_START_FOR_LEGACY_PAGES_ONLY } from '@atlassian/navigation-system/layout/main';

// SSR
export const SSR_LAYOUT_DATA_ATTR = 'data-main-content';
export const SSR_IFRAME_ATTR = 'data-flex-container';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/ui-styling-standard/no-styled
export const LayoutMainWrapper = styled.div<{
	showBreadcrumbNavHeader?: boolean;
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
}>({
	display: 'contents',
	// AKMainContent rules can be removed when Nav4 is rolled out
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'#AkMainContent': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles, @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		width: `calc(100vw - ${LEFT_PANEL_WIDTH} - ${LEFT_SIDEBAR_WIDTH} - ${RIGHT_PANEL_WIDTH} - ${RIGHT_SIDEBAR_WIDTH}) !important`,
		transition: `width 300ms cubic-bezier(0.2, 0, 0, 1) 0s`,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		paddingTop: ({ showBreadcrumbNavHeader }) => (showBreadcrumbNavHeader ? '60px' : '0px'),
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& #jira-frontend': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		height: `calc(100vh - ${TOP_NAVIGATION_HEIGHT})`,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
		width: `calc(
${/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766 */ ''}
      100vw - ${LEFT_PANEL_WIDTH} - ${LEFT_SIDEBAR_WIDTH} - ${RIGHT_PANEL_WIDTH} - ${RIGHT_SIDEBAR_WIDTH}
    ) !important`,
		transition: 'width 300ms cubic-bezier(0.2, 0, 0, 1) 0s',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/ui-styling-standard/no-styled
export const ZIndexWrapper = styled.div<{
	zIndex: number;
	showQuickStart: boolean;
}>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles
	zIndex: (props) => props.zIndex,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	"nav[aria-label='space navigation']": {
		minWidth: '0px',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles
	display: ({ showQuickStart }) => (showQuickStart ? 'block' : 'none'),
});

// This uses CCS variables from atlaskit/page-layout to make sure
// our SSR pages are positioned inside the new layoutManager despite being siblings in the HTML
export const StyleFixSSRContentForNewLayoutWithRightSidebar = (totalHeight: number) => {
	// eslint-disable-next-line @atlaskit/design-system/no-css-tagged-template-expression -- Ignored via go/DSP-18766
	return css`
		#ui-global-sidebar-section {
			z-index: auto !important;
			width: 100%;
		}

		[${SSR_LAYOUT_DATA_ATTR}], [${SSR_IFRAME_ATTR}] {
			width: calc(
				100% - ${LEFT_PANEL_WIDTH} - ${LEFT_SIDEBAR_WIDTH} - ${RIGHT_PANEL_WIDTH} -
					${RIGHT_SIDEBAR_WIDTH}
			) !important;
			top: ${totalHeight + 2}px !important;
			position: absolute;
			left: calc(${LEFT_PANEL_WIDTH} + ${LEFT_SIDEBAR_WIDTH});
			z-index: auto;
			box-sizing: border-box;

			&#admin-content {
				/* admin nav -> manage apps */
				.upm-manage {
					#upm-manage-type, /* app type dropdown filter */
        .upm-details .upm-plugin-info .upm-column {
						/* expand accordion to show app detail -> 3 column info table */
						z-index: 1;
					}
				}
			}
		}
		[${SSR_IFRAME_ATTR}] > [${SSR_LAYOUT_DATA_ATTR}] {
			display: none;
		}

		/* add overflow scrolling to ssred pages with heights longer than side navigation */
		[${SSR_IFRAME_ATTR}] > main#main.aui-page-panel {
			overflow: hidden;
			overflow-y: auto;
			height: calc(100vh - ${UNSAFE_MAIN_BLOCK_START_FOR_LEGACY_PAGES_ONLY} - 2px);
			min-height: calc(
				100vh - ${UNSAFE_MAIN_BLOCK_START_FOR_LEGACY_PAGES_ONLY} - 2px
			); /* for responsiveness in small height windows */
		}
	`;
};

// This uses CCS variables from atlaskit/page-layout to make sure
// our SSR pages are positioned inside the new layoutManager despite being siblings in the HTML
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @compiled/no-exported-css
export const fixSSRContentForNewLayoutWithRightSidebarStyles = `
#ui-global-sidebar-section {
	z-index: auto !important;
	width: 100%;
}

[${SSR_LAYOUT_DATA_ATTR}], [${SSR_IFRAME_ATTR}] {
	width: calc(
		100% - ${LEFT_PANEL_WIDTH} - ${LEFT_SIDEBAR_WIDTH} - ${RIGHT_PANEL_WIDTH} -
			${RIGHT_SIDEBAR_WIDTH}
	) !important;
	position: absolute;
	left: calc(${LEFT_PANEL_WIDTH} + ${LEFT_SIDEBAR_WIDTH});
	z-index: auto;
	box-sizing: border-box;

	&#admin-content {
		/* admin nav -> manage apps */
		.upm-manage {
			#upm-manage-type, /* app type dropdown filter */
		.upm-details .upm-plugin-info .upm-column {
				/* expand accordion to show app detail -> 3 column info table */
				z-index: 1;
			}
		}
	}
}
[${SSR_IFRAME_ATTR}] > [${SSR_LAYOUT_DATA_ATTR}] {
	display: none;
}

/* add overflow scrolling to ssred pages with heights longer than side navigation */
[${SSR_IFRAME_ATTR}] > main#main.aui-page-panel {
	overflow: hidden;
	overflow-y: auto;
	height: calc(100vh - ${UNSAFE_MAIN_BLOCK_START_FOR_LEGACY_PAGES_ONLY} - 2px);
	min-height: calc(
		100vh - ${UNSAFE_MAIN_BLOCK_START_FOR_LEGACY_PAGES_ONLY} - 2px
	); /* for responsiveness in small height windows */
}
`;

//CSS hack for code block
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-css-tagged-template-expression -- Ignored via go/DSP-18766
export const styleCodeBlockFix = css`
	.code-block.right-shadow {
		z-index: 2;
	}
`;

//CSS hack for noPrint
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-css-tagged-template-expression -- Ignored via go/DSP-18766
export const styleNoPrintNav = css`
	[data-ds--page-layout--slot='top-navigation'],
	[data-ds--page-layout--slot='left-sidebar'] {
		@media print {
			display: none !important;
		}
	}
`;

//To make sure we are using the same font family set by atlassian-frontend theme.
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-css-tagged-template-expression -- Ignored via go/DSP-18766
export const fontFamilyCLSFix = css`
	.PageContent {
		font-family: ${token('font.family.body')};
	}
`;

//to remove cls from more action dropdown
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-css-tagged-template-expression -- Ignored via go/DSP-18766
export const moreActionDropdownCLSFix = css`
	[data-testid='content-tools-dropdown-menu--content'] {
		width: 220px;
	}
`;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @compiled/no-exported-css
export const globalStylesCompiled = `
	/* CSS hack for code block */
	.code-block.right-shadow {
		z-index: 2;
	}
	/* CSS hack for noPrint */
	[data-ds--page-layout--slot='top-navigation'],
	[data-ds--page-layout--slot='left-sidebar'] {
		@media print {
			display: none !important;
		}
	}
	/* To make sure we are using the same font family set by atlassian-frontend theme. */
	.PageContent {
		font-family: ${token('font.family.body')};
	}
	/* To remove cls from more action dropdown */
	[data-testid='content-tools-dropdown-menu--content'] {
		width: 220px;
	}
`;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/ui-styling-standard/no-styled
export const ContentRightSidePanel = styled.div({
	height: '100%',
	width: '100%',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	'& > div': {
		height: '100%',
		width: '100%',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
		'& > div': {
			position: 'static',
		},
	},
});
