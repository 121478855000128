import gql from 'graphql-tag';

export const SpaceDetailQuery = gql`
	query SpaceDetailQuery($spaceKey: String) {
		space(key: $spaceKey) {
			id
			name
			alias
			key
			type
			dataClassificationTags
		}
	}
`;
