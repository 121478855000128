import React, { useCallback } from 'react';
import { useIntl } from 'react-intl-next';

import { RightSidebar } from '@atlaskit/page-layout';

import {
	useGetCurrentPreviewPanel,
	useGetPreviewPanelsActions,
} from '@atlassian/preview-panels-api';
import { Panel } from '@atlassian/navigation-system/layout/panel';
import { PanelSplitter } from '@atlassian/navigation-system/layout/panel-splitter';

import { fg } from '@confluence/feature-gating';
import { useIsNav4Enabled } from '@confluence/nav4-enabled';

import { usePreviewPanelWidth } from './usePreviewPanelWidth';
import { messages } from './messages';

const PreviewPanelSplitter = () => {
	const intl = useIntl();
	const { setPanelWidth } = useGetPreviewPanelsActions();

	const onResizeEnd = useCallback(
		({ finalWidth }: { finalWidth: number }) => {
			void setPanelWidth(finalWidth);
		},
		[setPanelWidth],
	);

	return (
		<PanelSplitter label={intl.formatMessage(messages.panelSplitter)} onResizeEnd={onResizeEnd} />
	);
};

const NavWrapper = ({
	children,
	width,
}: {
	children: React.ReactElement;
	width: number | undefined;
}) => {
	const isNav4Enabled = useIsNav4Enabled();
	if (isNav4Enabled) {
		return (
			<Panel defaultWidth={width}>
				<PreviewPanelSplitter />
				{children}
			</Panel>
		);
	} else {
		return <RightSidebar width={width}>{children}</RightSidebar>;
	}
};

export function PreviewPanelContainer() {
	const { content: PanelContent, data = {} } = useGetCurrentPreviewPanel();
	const { width } = usePreviewPanelWidth();

	if (!fg('confluence_preview_panels')) {
		return null;
	}

	if (!PanelContent) {
		return null;
	}

	return (
		<NavWrapper width={width}>
			<PanelContent {...data} />
		</NavWrapper>
	);
}
