import React, { useState } from 'react';

import { HomeActionsAttachRef, HomeActionsElement } from './home-actions-context';

/**
 * Provider for the home actions container element contexts.
 *
 * We are using a [ref callback](https://react.dev/reference/react-dom/components/common#ref-callback) along with a state
 * for storing the element once it has mounted, so that the side nav can bind event listeners to the element
 * once it is mounted.
 *
 * Otherwise, the side nav can be mounted before the element (e.g. if the element is lazy loaded), which would prevent the
 * event listeners from being set up.
 *
 * State is required as opposed to just a ref so that the effects in the side nav can react to the element actually being mounted,
 * as ref values cannot be added as effect dependencies.
 */
export const HomeActionsProvider = ({ children }: { children: React.ReactNode }) => {
	const [element, setElement] = useState<HTMLDivElement | null>(null);

	return (
		<HomeActionsElement.Provider value={element}>
			<HomeActionsAttachRef.Provider value={setElement}>{children}</HomeActionsAttachRef.Provider>
		</HomeActionsElement.Provider>
	);
};
