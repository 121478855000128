import React from 'react';

import { PreviewPanelsProvider } from '@atlassian/preview-panels-api';

import { NotesContextProvider } from '@confluence/notes-util/entry-points/NotesContext';
import { RightSidebarContextProvider } from '@confluence/page-layout-context';
import { InProductHelpProvider } from '@confluence/in-product-help';
import { fg } from '@confluence/feature-gating';

type PageLayoutContextProviderWrapperPropsType = {
	children: React.ReactNode;
};

export const PageLayoutContextProviderWrapper = ({
	children,
}: PageLayoutContextProviderWrapperPropsType) => {
	if (!fg('confluence_preview_panels')) {
		return (
			<RightSidebarContextProvider>
				<NotesContextProvider>
					<InProductHelpProvider>{children}</InProductHelpProvider>
				</NotesContextProvider>
			</RightSidebarContextProvider>
		);
	}

	return (
		<PreviewPanelsProvider>
			<RightSidebarContextProvider>
				<NotesContextProvider>
					<InProductHelpProvider>{children}</InProductHelpProvider>
				</NotesContextProvider>
			</RightSidebarContextProvider>
		</PreviewPanelsProvider>
	);
};
