import type { QueryOptions, ApolloClient } from 'apollo-client';
import type { DocumentNode } from 'graphql';
import gql from 'graphql-tag';

import { getSSRRenderInputs } from '@confluence/ssr-utilities';

import { getOperationName, UNEXPECTED_RESPONSE } from './getOperationName';
import { getQueryName } from './getQueryName';
import { flagQueryPreloadedByNetworkOnly } from './getQueryParamsAndSetFetchPolicyForNetworkOnlyQuery';

/**
 * Same as Apollo's query function but only used in preloader functions
 */
export function query<Q, V>({
	query,
	variables,
	/**
	 * How to preload with fresh data correctly.
	 * In preloader: query with fetchPolicy: "network-only"
	 * In component: query getQueryParamsAndSetFetchPolicyForNetworkOnlyQuery or getQueryPropsAndSetFetchPolicyForNetworkOnlyQuery
	 */
	fetchPolicy = 'cache-first',
	errorPolicy = 'all',
	context,
	client = window.GLOBAL_APOLLO_CLIENT,
}: QueryOptions<V> & { client?: ApolloClient<any> }): Promise<{ data: Q | null }> {
	if (!client) {
		// Referencing to the client via global variable to avoid circular dependency
		// This should never happen and will be removed in the future
		return Promise.resolve(UNEXPECTED_RESPONSE);
	}
	if (typeof query === 'string') {
		// For backward compatibility. We shouldn't really doing this.
		query = gql(query);
	}

	const name = getOperationName(query);
	const queryName = getQueryName(name, variables);

	// This is indirect reference to next/packages/graphql/src/links/DevOnlyGraphqlErrorGenerator.ts
	if (process.env.NODE_ENV !== 'production' && (window as any).graphqlOperationController) {
		const operationController = (window as any).graphqlOperationController;
		const { shouldGenerateError } = operationController.onOperationRequested(name);
		if (shouldGenerateError) {
			return Promise.resolve(UNEXPECTED_RESPONSE);
		}
	}

	let single = true;
	// eslint-disable-next-line check-react-ssr-usage/no-react-ssr
	if (process.env.REACT_SSR) {
		const queryName = getQueryName(name, variables);
		const { batchQueries } = getSSRRenderInputs();
		if (queryName in (batchQueries || {})) {
			single = false;
		}
	}

	// If the query has already been preloaded with network only
	// Subsequent query with same name and variable should be done with cache-first to avoid duplicate network request
	flagQueryPreloadedByNetworkOnly(queryName, variables, fetchPolicy);

	return client.query({
		query,
		variables,
		errorPolicy,
		fetchPolicy,
		context: {
			single,
			...context,
		},
	});
}

export function writeQuery<TData, TVariable>(options: {
	query: DocumentNode;
	variables: TVariable;
	data: TData;
	client?: ApolloClient<any>;
}) {
	const client = options.client || window.GLOBAL_APOLLO_CLIENT;
	if (client) {
		client.writeQuery(options);
	}
}
